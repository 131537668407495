import { addHours } from "date-fns";

export class Api {
  private token: string | undefined;

  public async authorize(username: string, password: string){
    try {
      const response = await fetch(this.url('/auth/login_check'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': window.location.hostname
        },
        body: JSON.stringify({login: username, password})
      })

      const { refresh_token, token, data } = await response.json();

      this.token = token

      return {token, refresh_token, data}
    } catch (e) {
      throw new Error('Failed to fetch: ' + e)
    }
  }

  public async changeEmail(email: string) {
    return await this.authorizedFetch(this.url('/secure/user/change_email'), {
      body: JSON.stringify({email})
    })
  }

  public async getOffers(){
    return await this.authorizedFetch(this.url('/secure/order'), {method: 'GET'})
  }

  public async getUsers(){
    return await this.authorizedFetch(this.url('/secure/user'), {method: 'GET'})
  }

  public async getMessages(page = 1){
    return await this.authorizedFetch(this.url('/mail_queue/'+"?page=" + page+'&order=DESC'), {method: 'GET'})
  }

  public async changePassword(id: number, password: string, newPassword: string) {
    return await this.authorizedFetch(this.url(`/user/${id}/change_password`), {
      method: "PUT",
      body: JSON.stringify({oldPassword: password, newPassword: newPassword})
    })
  }

  public async updateStatus(id: number, status: number) {
    return await this.authorizedFetch(this.url(`/secure/order/${id}`), {
      method: "POST",
      body: JSON.stringify({status: status})
    })
  }

  public async updateUserStatus(id: number, user: any) {
    return await this.authorizedFetch(this.url(`/user/${id}/change_status`), {
      method: "PUT",
      body: JSON.stringify(user)
    })
  }

  public async updateUser(id: number, user: any) {
    return await this.authorizedFetch(this.url(`/user/${id}`), {
      method: "PUT",
      body: JSON.stringify(user)
    })
  }

  public async createUser(user: any) {
    return await this.authorizedFetch(this.url(`/user/`), {
      method: "POST",
      body: JSON.stringify(user)
    })
  }

  public async createCategory(data) {
    return await this.authorizedFetch(this.url(`/attribute/category`), {
      method: "POST",
      body: JSON.stringify(data)
    })
  }

  public async editCategory(data) {
    return await this.authorizedFetch(this.url(`/attribute/category`), {
      method: "PUT",
      body: JSON.stringify(data)
    })
  }

  public async deleteFAQ(data) {
    return await this.authorizedFetch(this.url(`/faq/`+data), {
      method: "DELETE",
    })
  }

  public async addFAQ(data){
    return await this.authorizedFetch(this.url(`/faq/`), {
      method: "POST",
      body: JSON.stringify({...data, answer: data.answer.b64encode()})
    })
  }

  public async editCertTemp(data){
    return await this.authorizedFetch(this.url(`/certificate_template/`+data.id), {
      method: "PUT",
      body: JSON.stringify({...data, htmlContent: data.htmlContent.b64encode(), htmlEnglishContent: data.htmlEnglishContent?.b64encode()})
    })
  }

  public async updateCertPlanting(id, data) {
    return await this.authorizedFetch(this.url(`/underplanting/${id}/certificate_company_template`), {
      method: "PUT",
      body: JSON.stringify({...data, htmlContent: data.htmlContent.b64encode()})
    })
  }

  public async updateCertPlantingPerson(id, data) {
    return await this.authorizedFetch(this.url(`/underplanting/${id}/certificate_person_template`), {
      method: "PUT",
      body: JSON.stringify({...data, htmlContent: data.htmlContent.b64encode()})
    })
  }

  public async updateMailPlanting(id, data) {
    return await this.authorizedFetch(this.url(`/underplanting/${id}/mail_company_template`), {
      method: "PUT",
      body: JSON.stringify({...data})
    })
  }

  public async updateMailPlantingPerson(id, data) {
    return await this.authorizedFetch(this.url(`/underplanting/${id}/mail_person_template`), {
      method: "PUT",
      body: JSON.stringify({...data})
    })
  }

  public async addCertTemp(data){
    return await this.authorizedFetch(this.url(`/certificate_template/`), {
      method: "POST",
      body: JSON.stringify({...data, htmlEnglishContent: data.htmlEnglishContent?.b64encode(), htmlContent: data.htmlContent.b64encode()})
    })
  }

  public async delCertTemp(data){
    return await this.authorizedFetch(this.url(`/certificate_template/`+data.id), {
      method: "DELETE",
    })
  }


  public async editFAQ(data){
    return await this.authorizedFetch(this.url(`/faq/`+data.id), {
      method: "PUT",
      body: JSON.stringify({...data, answer: data.answer.b64encode()})
    })
  }

  public async deleteAttribute(data) {
    return await this.authorizedFetch(this.url(`/attribute`), {
      method: "DELETE",
      body: JSON.stringify(data)
    })
  }

  public async changeDefaultAttribute(data) {
    return await this.authorizedFetch(this.url(`/attribute/default`), {
      method: "POST",
      body: JSON.stringify(data)
    })
  }

  public async getCarts(){
    return await this.authorizedFetch(this.url('/secure/cart'), {
      method: 'GET'
    })
  }

  public async getCertTemplate(){
    return await this.authorizedFetch(this.url('/certificate_template/' + '?itemsPerPage=10000'), {
      method: 'GET'
    })
  }

  public async fetchUserCert(id){
    return await this.authorizedFetch(this.url('/certificate_template/'+id), {
      method: 'GET'
    })
  }

  public async getFAQs(page = 1){
    return await this.authorizedFetch(this.url('/faq_group/'+"?page=" + page), {
      method: 'GET'
    })
  }

  public async getOrders(page = 1, filter = {}){
    let base = '/order/'+"?page=" + page+'&order=DESC'

    Object.entries(filter).forEach(([key, value]) => {
      if(value !== ''){
        base += `&${key}=${value}`
      }
    })

    return await this.authorizedFetch(this.url(base), {
      method: 'GET'
    })
  }

  public async  getAllPlants() {
      return await this.authorizedFetch(this.url(`/plant/all_without_pagination`), {
      method: 'GET'
    })
  }

  public async getUnderPlantingById(id){
    return await this.authorizedFetch(this.url(`/underplanting/${id}`), {
      method: 'GET'
    })
  }

  public async getVoucherByID(id){
    return await this.authorizedFetch(this.url(`/voucher/${id}`), {
      method: 'GET'
    })
  }

  public async getUserPlanting(id, ecommerce, page = 1){
    return await this.authorizedFetch(this.url(ecommerce? `/user/${id}/tree_groups?type=ecommerce`+"&page=" + page:`/user/${id}/tree_groups`+"?page=" + page), {
      method: 'GET'
    })
  }

  public async deletePlanting(id){
    return await this.authorizedFetch(this.url(`/tree_group/${id}`), {
      method: 'DELETE'
    })
  }

  public async deleteOrder(id){
    return await this.authorizedFetch(this.url(`/order/${id}`), {
      method: 'DELETE'
    })
  }

  public async getUserPublicData(id){
    return await this.authorizedFetch(this.url(`/user_company/public/${id}`), {
      method: 'GET'
    })
  }

  public async getUserBillingData(id){
    return await this.authorizedFetch(this.url(`/user_company/billing/${id}`), {
      method: 'GET'
    })
  }

  public async getUserCerts(id, page=1){
    return await this.authorizedFetch(this.url(`/user/${id}/certificates`+"?page=" + page+'&order=DESC'), {
      method: 'GET'
    })
  }

  public async deleteUserCert(id){
    return await this.authorizedFetch(this.url(`/certificate/${id}`), {
      method: 'DELETE'
    })
  }

  public async getMailsTemplates(page = 1){
    return await this.authorizedFetch(this.url(`/mail_template/`+"?page=" + page), {
      method: 'GET'
    })
  }

  public async getUserTokens(id) {
    return await this.authorizedFetch(this.url(`/admin/user_token/${id}`), {
      method: 'GET'
    })
  }

  public async getUserPayments(id, page = 1){
    return await this.authorizedFetch(this.url(`/user/${id}/orders`+"?page=" + page+'&order=DESC'), {
      method: 'GET'
    })
  }
  public async requestInvoicePdf(id){
    return await this.authorizedFetch(this.url(`/invoice/${id}/pdf`), {
      method: 'GET'
    })
  }

  public async getMailType(id){
    return await this.authorizedFetch(this.url('/mail_type/'+id), {
      method: 'GET'
    })
  }

  public async getUserGroups(){
    return await this.authorizedFetch(this.url('/user_group/'), {
      method: 'GET'
    })
  }

  public async getPlanting(page = 1){
    return await this.authorizedFetch(this.url('/plant/'+"?page=" + page+'order=DESC'), {
      method: 'GET'
    })
  }

  public async getVoucher(page = 1){
    return await this.authorizedFetch(this.url('/voucher/'+"?page=" + page+'order=DESC'), {
      method: 'GET'
    })
  }

  public async getVoucherUser(page = 1, id) {
    return await this.authorizedFetch(this.url(`/user/${id}/vouchers/`+"?page=" + page+'order=DESC'), {
      method: 'GET'
    })
  }

  public async deleteVoucher(id) {
    return await this.authorizedFetch(this.url('/voucher/'+id), {
      method: 'DELETE'
    })
  }

  public async getSettings(page  = 1 ){
    return await this.authorizedFetch(this.url('/settings/'+"?page=" + page), {
      method: 'GET'
    })
  }

  public async getClients(page = 1, filter = {}, search = ''){
    let base = '/user/'+"?page=" + page+`&search=${search}`

    Object.entries(filter).forEach(([key, value]) => {
      if(value !== ''){
        base += `&${key}=${value}`
      }
    })

    return await this.authorizedFetch(this.url(base), {
      method: 'GET'
    })
  }

  public async getUnderPlanting(page = 1){
    return await this.authorizedFetch(this.url('/underplanting/'+"?page=" + page+`&order=DESC`), {
      method: 'GET'
    })
  }

  public async getCompanyNames(){
    return await this.authorizedFetch(this.url('/user_company/minimal_details'), {
      method: 'GET'
    })
  }


  public async markPayment(id){
    return await this.authorizedFetch(this.url(`/order/${id}/mark_as_paid`), {
      method: 'POST'
    })
  }

  public async removePayment(id){
    return await this.authorizedFetch(this.url(`/order/${id}`), {
      method: 'DELETE'
    })
  }

  public async getAttributes(){
    return await this.authorizedFetch(this.url('/attribute'), {
      method: 'GET'
    })
  }

  public async createVoucher(data){
    return await this.authorizedFetch(this.url('/voucher/generate'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  public async updateAttribute(data) {
    return await this.authorizedFetch(this.url('/attribute/'), {
      method: 'PUT',
      body: JSON.stringify(data)
    })
  }

  public async updateCertTemplate(data) {
    return await this.authorizedFetch(this.url('/certificate_template/'+data.id), {
      method: 'PUT',
      body: JSON.stringify(data)
    })
  }

public async markActivePlanting(id) {
  return await this.authorizedFetch(this.url(`/plant/${id}/make_as_active`), {
    method: 'PUT',
  })
}


  public async editMessTemp(data){
    return await this.authorizedFetch(this.url('/mail_template/'+data.id), {
      method: 'PUT',
      body: JSON.stringify(data)
    })
  }

  public async createAttribute(data) {
    return await this.authorizedFetch(this.url('/attribute'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  public async getStats(data) {
    console.log(data)
    return await this.authorizedFetch(this.url('/admin_statistics/system_statistics'), {
      method: 'POST',
      body: JSON.stringify({
        "fromDate": addHours(data[0], 3).toISOString().split('T')[0],
        "toDate": addHours(data[1], 3).toISOString().split('T')[0]
      })
    })
  }

  public async createGroup(data) {
    return await this.authorizedFetch(this.url('/faq_group'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  public async createPlanting(data) {
    return await this.authorizedFetch(this.url('/plant/'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  public async sendMessTemp(data) {
    return await this.authorizedFetch(this.url('/admin/send_test_mail'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  public async addUnderPlanting(data) {
    return await this.authorizedFetch(this.url('/underplanting/'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  public async updateUnderPlanting(id, data) {
    return await this.authorizedFetch(this.url('/underplanting/'+id), {
      method: 'PUT',
      body: JSON.stringify(data)
    })
  }

  public async updateOrder(id, data) {
    return await this.authorizedFetch(this.url('/order/'+id), {
      method: 'PUT',
      body: JSON.stringify(data)
    })
  }

  public async updateSettings(id, val) {
    return await this.authorizedFetch(this.url('/settings/'+id), {
      method: 'PUT',
      body: JSON.stringify({value: val})
    })
  }

  public async createOrderAdmin(data){
    return await this.authorizedFetch(this.url('/order/make_order_admin'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  public async updatePublicData(id: number, content: any){
    return await this.authorizedFetch(this.url(`/user_company/public/${id}`), {
      method: "PUT",
      body: JSON.stringify({...content,
        companySlug: content.companySlug || "",
        companyWordpressURL: content.companyWordpressURL || "",
        companyHeading:content.companyHeading || "",
        companyName: content.companyName || "",
        companyDescription: content.companyDescription || ""})
    })
  }

  public async updatePlanting(id, content){
    return await this.authorizedFetch(this.url(`/plant/${id}`), {
      method: "PUT",
      body: JSON.stringify(content)
    })
  }

  public async updateCompany(id: number, content: any){
    return await this.authorizedFetch(this.url(`/user_company/billing/${id}`), {
      method: "PUT",
      body: JSON.stringify(content)
    })
  }

  public async createPlantingGroup(data) {
    return await this.authorizedFetch(this.url(`/tree_group/`), {
      method: "POST",
      body: JSON.stringify({
        "userID": data,
        name: "",
        "type": 0
      })
    })
  }

  public async addLogo(id:number, formData: FormData): Promise<Response> {
    return await this.authorizedFetch(this.url("/upload/company_logo"), {
      method: "POST",
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': window.location.hostname
      },
    });
  }


  public async addTos(formData: FormData): Promise<Response> {
    return await this.authorizedFetch(this.url("/upload/underplanting_tos_pdf"), {
      method: "POST",
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': window.location.hostname
      },
    });
  }

  public async addPlantLogo(id:number, formData: FormData): Promise<Response> {
    return await this.authorizedFetch(this.url("/upload/plant_image"), {
      method: "POST",
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': window.location.hostname
      },
    });
  }

  public async createPreviewCert(data) {
    return await this.authorizedFetch(this.url(`/certificate_template/preview`), {
      method: "POST",
      body: JSON.stringify(data)
    })
  }

  public async updateContent(id: number, content: string){
    return await this.authorizedFetch(this.url(`/secure/content/${id}`), {
      method: "POST",
      body: JSON.stringify({value: content})
    })
  }

  public async addImage(formData: FormData){
    const response = await this.authorizedFetch(this.url('/attribute/image'), {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': window.location.hostname
      }
    })
    return response
  }

  public async resetPasswordEmail(email: string) {
    try {
      const response = await fetch(this.url('/user/reset_password/'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': window.location.hostname
        },
        body: JSON.stringify({
          email: email,
          returnUrl: window.location.origin + '/password/reset'
        })
      })

      return ''
    } catch (e) {
      throw new Error('Failed to fetch: ' + e)
    }

  }

  public async resetPasswordToken(token: string, password: string) {
    return await fetch(this.url('/user/reset_password/process'), {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.hostname
      },
      body: JSON.stringify({resetToken: token, password: password})
    })
  }

  public setToken(token: string){
    this.token = token
  }

  public isAuthorized(){
    return !!this.token
  }

  public url(path: string = ''){
    return process.env.REACT_APP_API_URL + path
  }

  public authorizedFetch(input: RequestInfo, init?: RequestInit){
    if(!this.isAuthorized){
      throw new Error('Not authorized')
    }

    return fetch(input, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': window.location.hostname
      },
      ...init
    })
  }
}

export default new Api()