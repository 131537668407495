String.prototype.b64encode = function () {
  return btoa(unescape(encodeURIComponent(this.toString())));
};

String.prototype.b64decode = function () {
  try {
    return decodeURIComponent(escape(atob(this.toString())));
  } catch (e) {
    return "";
  }
};

export {};
